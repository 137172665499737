import React from "react";
import clsx from "clsx";
import { Image, Text, Container, Button } from "@atoms";

const ThreeColumnContent = ({ col1, col2, image, className: _className }) => {
  return (
    <section className={clsx("my-10 sm:my-20", _className)}>
      <Container>
        <div className="grid auto-rows-fr grid-cols-1 gap-6 sm:grid-cols-3">
          <div>
            <Text variant="h5" className="mb-6">
              {col1.heading}
            </Text>
            <Text variant="sm" richText className="mb-6">
              {col1.copy}
            </Text>
            {col1.button.url && (
              <Button color="purple" size="sm" to={col1.button.url}>
                {col1.button.text}
              </Button>
            )}
          </div>
          <div>
            <Text variant="h5" className="mb-6">
              {col2.heading}
            </Text>
            <Text variant="sm" richText className="mb-6">
              {col2.copy}
            </Text>
            {col2.button.url && (
              <Button color="purple" size="sm" to={col2.button.url}>
                {col2.button.text}
              </Button>
            )}
          </div>
          <div className="relative min-h-1/4-screen">
            <Image
              image={image}
              ixParams={{ fit: "crop" }}
              fill
              className="rounded-3xl"
            />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default ThreeColumnContent;
